import React, { useEffect, useRef, useState } from 'react';
import { Navbar } from 'react-bootstrap';
import classNames from 'classnames';
import { useAppContext } from 'contexts/AppContext';
import Logo from 'components/common/Logo';
import { navbarBreakPoint } from 'config';
import TopNavRightSideNavItem from 'components/navbar/authenticated/TopNavRightSideNavItem';
import { useLocation } from 'react-router-dom';

const NavbarTop = () => {
  const { showBurgerMenu, navbarCollapsed, setConfig } = useAppContext();

  const { pathname } = useLocation();
  const isChat = pathname.includes('chat');

  const [showDropShadow, setShowDropShadow] = useState(false);

  const handleBurgerMenu = () => {
    setConfig('showBurgerMenu', !showBurgerMenu);
  };

  const setDropShadow = () => {
    const el = document.documentElement;
    if (el.scrollTop > 0) {
      setShowDropShadow(true);
    } else {
      setShowDropShadow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', setDropShadow);
    return () => window.removeEventListener('scroll', setDropShadow);
  }, []);

  const burgerMenuRef = useRef();

  return (
    <Navbar
      className={classNames('navbar-glass fs--1 navbar-top sticky-kit', {
        // 'navbar-glass-shadow': showDropShadow
        'navbar-glass-shadow': showDropShadow && !isChat
      })}
      expand={true}
    >
      <NavbarTopElements
        navbarCollapsed={navbarCollapsed}
        handleBurgerMenu={handleBurgerMenu}
        burgerMenuRef={burgerMenuRef}
      />
    </Navbar>
  );
};

const NavbarTopElements = ({ handleBurgerMenu }) => {
  const burgerMenuRef = useRef();
  return (
    <>
      <Navbar.Toggle
        ref={burgerMenuRef}
        className={classNames('toggle-icon-wrapper me-md-3 me-2', {
          'd-lg-none': false,
          [`d-${navbarBreakPoint}-none`]: true
        })}
        as="div"
      >
        <button
          className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
          onClick={handleBurgerMenu}
          id="burgerMenu"
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </Navbar.Toggle>

      <Logo at="navbar-top" width={30} id="topLogo" />
      <TopNavRightSideNavItem />
    </>
  );
};

export default NavbarTop;
