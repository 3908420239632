import React, { useEffect, useRef, useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import classNames from 'classnames';
import Logo from 'components/common/Logo';
import { navbarBreakPoint, topNavbarBreakpoint, appPaths } from 'config';
import TopNavRightSideNavItem from 'components/navbar/unauthenticated/TopNavRightSideNavItem';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useAppContext } from 'contexts/AppContext';

const NavbarTop = () => {
  const { navbarCollapsed, setConfig } = useAppContext();

  const { pathname } = useLocation();
  const isChat = pathname.includes('chat');

  const [showDropShadow, setShowDropShadow] = useState(false);

  const handleBurgerMenu = () => {
    setConfig('navbarCollapsed', !navbarCollapsed);
  };

  const setDropShadow = () => {
    const el = document.documentElement;
    if (el.scrollTop > 0) {
      setShowDropShadow(true);
    } else {
      setShowDropShadow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', setDropShadow);
    return () => window.removeEventListener('scroll', setDropShadow);
  }, []);

  const burgerMenuRef = useRef();

  return (
    <Navbar
      className={classNames('navbar-glass py-3 fs--1 navbar-top sticky-kit', {
        'navbar-glass-shadow': showDropShadow && !isChat
      })}
      expand={topNavbarBreakpoint}
    >
      <NavbarTopElements
        navbarCollapsed={navbarCollapsed}
        handleBurgerMenu={handleBurgerMenu}
        burgerMenuRef={burgerMenuRef}
      />
    </Navbar>
  );
};

const NavbarTopElements = ({ handleBurgerMenu, navbarCollapsed }) => {
  const burgerMenuRef = useRef();
  return (
    <>
      <Navbar.Toggle
        ref={burgerMenuRef}
        className={classNames('toggle-icon-wrapper me-md-3 me-2', {
          'd-lg-none': true,
          [`d-${navbarBreakPoint}-none`]: false
        })}
        as="div"
      >
        <button
          className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
          onClick={handleBurgerMenu}
          id="burgerMenu"
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </Navbar.Toggle>
      <Logo
        to={appPaths.bounties.list}
        at="navbar-top"
        width={30}
        id="topLogo"
      />
      <Navbar.Collapse in={navbarCollapsed} className="scrollbar pb-3 pb-lg-0">
        <Nav navbar>
          <Nav.Link as={Link} to={appPaths.bounties.list}>
            Bounties
          </Nav.Link>
          <Nav.Link
            as={Link}
            to={'https://docs.swarmnode.ai/docs/getting-started/'}
            target="_blank"
          >
            Documentation
          </Nav.Link>
          <Nav.Link
            as={Link}
            to={'https://docs.swarmnode.ai/docs/api/v1/introduction/'}
            target="_blank"
          >
            REST API
          </Nav.Link>
          <Nav.Link
            as={Link}
            to={'https://docs.swarmnode.ai/docs/sdk/introduction/'}
            target="_blank"
          >
            Python SDK
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
      <TopNavRightSideNavItem />
    </>
  );
};

export default NavbarTop;
