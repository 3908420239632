import { appPaths } from 'config';

export const agents = {
  label: 'Agents',
  labelDisable: false,
  children: [
    {
      name: 'Create an Agent',
      icon: 'plus',
      to: appPaths.agents.create,
      active: true
    },
    {
      name: 'My Agents',
      icon: 'robot',
      to: appPaths.agents.list,
      active: true
    }
  ]
};

export const stores = {
  label: 'Stores',
  labelDisable: false,
  children: [
    {
      name: 'Create a Store',
      icon: 'plus',
      to: appPaths.stores.create,
      active: true
    },
    {
      name: 'My Stores',
      icon: 'database',
      to: appPaths.stores.list,
      active: true
    }
  ]
};

export const vectorStores = {
  label: 'Vector stores',
  labelDisable: false,
  children: [
    {
      name: 'Create a Vector Store',
      icon: 'plus',
      to: appPaths.vectorStores.qdrant.create,
      active: true
    },
    {
      name: 'My Vector Stores',
      icon: 'database',
      to: appPaths.vectorStores.qdrant.list,
      active: true
    }
  ]
};

export const explore = {
  label: 'Explore',
  labelDisable: false,
  children: [
    {
      name: 'Marketplace',
      icon: 'shopping-cart',
      to: appPaths.marketplace.list,
      active: true
    }
  ]
};

export const learn = {
  label: 'Learn',
  labelDisable: false,
  children: [
    {
      name: 'Documentation',
      active: true,
      icon: 'book-open',
      children: [
        {
          name: 'Getting Started',
          to: 'https://docs.swarmnode.ai/docs/getting-started',
          exact: true,
          active: true,
          newTab: true
        },
        {
          name: 'Data Storage',
          to: 'https://docs.swarmnode.ai/docs/getting-started/data-storage',
          exact: true,
          active: true,
          newTab: true
        },
        {
          name: 'REST API',
          to: 'https://docs.swarmnode.ai/docs/api/v1/introduction',
          exact: true,
          active: true,
          newTab: true
        },
        {
          name: 'Python SDK',
          to: 'https://docs.swarmnode.ai/docs/sdk/introduction',
          exact: true,
          active: true,
          newTab: true
        }
      ]
    }
  ]
};

export default [agents, stores, vectorStores, explore, learn];
