import React, { useState, useEffect } from 'react';
import CreateAgentForm from 'components/agents/create-agent/CreateAgentForm';
import { getAgentTemplate } from 'helpers/requests/agent-templates';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const CreateAgent = () => {
  const [agentTemplate, setAgentTemplate] = useState(null);
  const location = useLocation();

  let handleGetAgentTemplate = async agentTemplateId => {
    toast.warning('Loading the template. Please wait.');
    const r = await getAgentTemplate(agentTemplateId);
    if (r.success) {
      setAgentTemplate(r.response.data);
      toast.success('Template was loaded successfully.');
    } else {
      toast.error('Failed to load the template.');
    }
  };

  let getTemplateIdFromUrl = () => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('template');
  };

  useEffect(() => {
    const templateId = getTemplateIdFromUrl();
    if (templateId !== null) {
      handleGetAgentTemplate(templateId);
    }
  }, []);

  return <CreateAgentForm agentTemplate={agentTemplate} />;
};

export default CreateAgent;
