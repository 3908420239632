import styled from '@emotion/styled';
import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { Placeholder } from 'react-bootstrap';

const CardWrapper = styled.div({
  aspectRatio: '1'
});

const LoadingBountyCard = () => {
  return (
    <Col xs={12} md={6} lg={3} className="mb-4">
      <CardWrapper>
        <Card className="h-100">
          <Card.Header>
            <Row>
              <Col xs={9}>
                <Card.Title>
                  <small>
                    <Placeholder as={Card.Text} animation="glow">
                      <Placeholder xs={4} />
                    </Placeholder>
                  </small>
                </Card.Title>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="py-1 d-flex flex-column align-items-center justify-content-center">
            <small className="w-100">
              <Placeholder as={Card.Text} animation="glow">
                <Placeholder xs={12} />
                <Placeholder xs={12} />
                <Placeholder xs={12} />
                <Placeholder xs={12} />
                <Placeholder xs={12} />
              </Placeholder>
            </small>
          </Card.Body>
          <Card.Footer></Card.Footer>
        </Card>
      </CardWrapper>
    </Col>
  );
};

const Loading = ({ size }) => {
  return (
    <>
      {[...Array(size)].map((_, index) => (
        <LoadingBountyCard key={index} />
      ))}
    </>
  );
};

export default Loading;
