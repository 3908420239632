import React, { useReducer, useEffect } from 'react';
import AppContext from 'contexts/AppContext';
import { useAuthContext } from 'contexts/AuthContext';
import { settings } from 'config';
import { getColor, getItemFromStore } from 'helpers/utils';
import { configReducer } from 'reducers/configReducer';
import useToggleStyle from 'hooks/useToggleStyle';
import { getUserPreferences } from 'helpers/requests/users';
import { Chart as ChartJS, registerables } from 'chart.js';
ChartJS.register(...registerables);

const AppContextProvider = props => {
  let { isAuthenticated } = useAuthContext();

  let handleGetUserPreferences = async () => {
    const r = await getUserPreferences();
    if (r.success) {
      setConfig('realTimeExecutions', r.response.data.real_time_executions);
      setConfig('codeEditorThemeDark', r.response.data.code_editor_theme_dark);
      setConfig(
        'codeEditorThemeLight',
        r.response.data.code_editor_theme_light
      );
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      handleGetUserPreferences();
    }
  }, [isAuthenticated]);

  const configState = {
    isFluid: getItemFromStore('isFluid', settings.isFluid),
    isRTL: getItemFromStore('isRTL', settings.isRTL),
    isDark: getItemFromStore('isDark', settings.isDark),
    realTimeExecutions: settings.realTimeExecutions,
    codeEditorThemeDark: settings.codeEditorThemeDark,
    codeEditorThemeLight: settings.codeEditorThemeLight,
    disabledNavbarPosition: [],
    isNavbarVerticalCollapsed: getItemFromStore(
      'isNavbarVerticalCollapsed',
      settings.isNavbarVerticalCollapsed
    ),
    navbarStyle: getItemFromStore('navbarStyle', settings.navbarStyle),
    currency: settings.currency,
    showBurgerMenu: settings.showBurgerMenu,
    showSettingPanel: false,
    navbarCollapsed: false
  };

  const [config, configDispatch] = useReducer(configReducer, configState);

  const { isLoaded } = useToggleStyle(
    config.isRTL,
    config.isDark,
    configDispatch
  );

  const setConfig = (key, value) => {
    configDispatch({
      type: 'SET_CONFIG',
      payload: {
        key,
        value,
        setInStore: [
          'isFluid',
          'isRTL',
          'isDark',
          'isNavbarVerticalCollapsed',
          'navbarStyle'
        ].includes(key)
      }
    });
  };

  if (!isLoaded) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: config.isDark ? getColor('dark') : getColor('light')
        }}
      />
    );
  }

  return (
    <AppContext.Provider value={{ config, setConfig, configDispatch }}>
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
