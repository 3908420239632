import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, Form, Row, Col, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { setFormErrors } from 'helpers/errors';
import { updateBounty } from 'helpers/requests/bounties';
import { appPaths } from 'config';
import { handleUnexpectedStatusCode } from 'helpers/errors';

const UpdateBountyForm = ({ bounty }) => {
  const [submittingForm, setSubmittingForm] = useState(false);
  const isMounted = useRef(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm({
    defaultValues: {
      name: bounty.name,
      description: bounty.description,
      amount_in_tokens: bounty.amount_in_tokens,
      contact_information: bounty.contact_information
    }
  });

  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const submitForm = async data => {
    setSubmittingForm(true);

    const r = await updateBounty(bounty.id, {
      name: data.name,
      description: data.description,
      amount_in_tokens: data.amount_in_tokens,
      contact_information: data.contact_information
    });

    if (r.success) {
      if (isMounted.current) {
        navigate(appPaths.bounties.detail.replace(':id', bounty.id));
      }
      toast.success('Bounty was edited successfully.');
    } else {
      if (r.response.status === 400) {
        setFormErrors(r.response.data, setError);
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
    setSubmittingForm(false);
  };

  return (
    <>
      <Form noValidate onSubmit={handleSubmit(submitForm)}>
        <Card className="mb-3 bg-light">
          <Card.Body>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} md={6} sm={12}>
                <Form.Label>Name*</Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  isInvalid={!!errors.name}
                  {...register('name', {
                    required: 'Enter bounty name.'
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" as={Col} md={6} sm={12}>
                <Form.Label>Bounty amount*</Form.Label>
                <Form.Control
                  name="amount_in_tokens"
                  type="number"
                  isInvalid={!!errors.amount_in_tokens}
                  {...register('amount_in_tokens', {
                    required: 'Enter bounty amount in tokens',
                    validate: value =>
                      Number.isInteger(Number(value)) ||
                      'Bounty amount must be a whole number',
                    valueAsNumber: true
                  })}
                />
                <Form.Text className="text-muted">
                  The amount of SNAI that you will pay to the developer.
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {errors.amount_in_tokens?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Card.Body>
        </Card>

        <Card className="mb-3 bg-light">
          <Card.Body>
            <Row className="mb-3 g-3">
              <Form.Group className="mb-3">
                <Form.Label>Agent Description*</Form.Label>
                <Form.Control
                  name="description"
                  isInvalid={!!errors.description}
                  {...register('description', {
                    required: 'Enter agent description'
                  })}
                  as="textarea"
                  rows={16}
                />
                <Form.Text className="text-muted">
                  Describe in detail what the agent is supposed to do.
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {errors.description?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Card.Body>
        </Card>

        <Card className="mb-3 bg-light">
          <Card.Body>
            <Row className="mb-3 g-3">
              <Form.Group className="mb-3">
                <Form.Label>Contact Information*</Form.Label>
                <Form.Control
                  name="contact_information"
                  isInvalid={!!errors.contact_information}
                  {...register('contact_information', {
                    required: 'Enter contact information'
                  })}
                  as="textarea"
                  rows={4}
                />
                <Form.Text className="text-muted">
                  Indicate how you can be contacted (Discord, Telegram, email,
                  etc.)
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {errors.contact_information?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Card.Body>
        </Card>

        {errors.root?.nonFieldError?.message && (
          <Alert variant="danger">{errors.root.nonFieldError.message}</Alert>
        )}

        <Card>
          <Card.Body>
            <Form.Group className="d-flex justify-content-end">
              <Button type="submit" disabled={submittingForm}>
                Save
              </Button>
            </Form.Group>
          </Card.Body>
        </Card>
      </Form>
    </>
  );
};

export default UpdateBountyForm;
