import AgentTemplateCard from 'components/agent-templates/agent-template-list/AgentTemplateCard';
import BountyCard from 'components/bounties/bounty-list/BountyCard';
import { getAgentTemplates } from 'helpers/requests/agent-templates';
import { getBounties } from 'helpers/requests/bounties';
import React from 'react';
import { Container } from 'react-bootstrap';
import ProductBlock from './ProductBlock';
import { appPaths } from 'config';

const MarketPlace = () => {
  return (
    <div>
      <Container className="pt-4">
        <div>
          <ProductBlock
            getDataFunc={getBounties}
            Card={BountyCard}
            block="bounty"
            link={appPaths.bounties.list}
            heading="Bounties"
          />
        </div>
        <div className="mt-7">
          <ProductBlock
            getDataFunc={getAgentTemplates}
            Card={AgentTemplateCard}
            block="template"
            link={appPaths.agentTemplates.list}
            heading="Templates"
          />
        </div>
      </Container>
    </div>
  );
};

export default MarketPlace;
