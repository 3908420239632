import React, { useEffect, useState, useRef } from 'react';
import { Card, Alert } from 'react-bootstrap';
import TerminalModal from 'components/common/TerminalModal';
import { getExecutions } from 'helpers/requests/agents';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import Header from 'components/agents/agent-details/executions/Header';
import Table from 'components/agents/agent-details/executions/Table';
import getColumns from 'components/agents/agent-details/executions/get-columns';
import Loading from 'components/agents/agent-details/executions/Loading';
import { useAppContext } from 'contexts/AppContext';
import { apiWsPaths } from 'config';
import { getWsUrl } from 'helpers/ws-requests/ws';

const Executions = ({ agentId }) => {
  const [loading, setLoading] = useState(true);
  const [agentExecutions, setAgentExecutions] = useState([]);
  const [showTerminalModal, setShowTerminalModal] = useState(false);
  const [terminalModalContent, setTerminalModalContent] = useState('');
  const { realTimeExecutions } = useAppContext();

  const [executionSocket, setExecutionSocket] = useState(null);
  const executionSocketClosedIntentionally = useRef(false);

  const initializeExecutionSocket = () => {
    executionSocketClosedIntentionally.current = false;

    const ws = new WebSocket(
      getWsUrl(apiWsPaths.appAgents.executions(agentId))
    );
    ws.onmessage = function (e) {
      const newExecution = JSON.parse(e.data);

      setAgentExecutions(prevAgentExecutions => {
        const existingIndex = prevAgentExecutions.findIndex(
          execution => execution.id === newExecution.id
        );

        if (existingIndex !== -1) {
          // Replace existing execution with new data
          return prevAgentExecutions.map((execution, index) =>
            index === existingIndex ? newExecution : execution
          );
        } else {
          // Add new execution to the beginning of the array
          return [newExecution, ...prevAgentExecutions];
        }
      });
    };
    ws.onclose = function () {
      if (!executionSocketClosedIntentionally.current) {
        console.error(
          'Connection closed unexpectedly. Attempting to reconnect...'
        );
        initializeExecutionSocket();
        // Fetch all executions because maybe the connection closed while
        // an execution was received with status in_progress and we won't
        // receive the status update (because status update may arrive on
        // the backend before websockets reconnects) and it will remain
        // displayed as in_progress.
        handleGetExecutions();
      }
    };

    setExecutionSocket(ws);
  };

  const closeExecutionSocket = () => {
    if (executionSocket) {
      executionSocketClosedIntentionally.current = true;
      executionSocket.close();
      setExecutionSocket(null);
    }
  };

  let handleGetExecutions = async () => {
    const r = await getExecutions(agentId);
    if (r.success) {
      setAgentExecutions(r.response.data);
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
    setLoading(false);
  };

  const handleViewLogsClick = logs => {
    const content =
      logs.length > 0
        ? logs.map(({ log, time }) => `${time}: ${log}`).join('\n')
        : 'No logs to show.';
    setTerminalModalContent(content);
    setShowTerminalModal(true);
  };

  const handleViewReturnValueClick = returnValue => {
    setTerminalModalContent(returnValue);
    setShowTerminalModal(true);
  };

  const handleCloseTerminalModal = () => {
    setShowTerminalModal(false);
  };

  useEffect(() => {
    if (realTimeExecutions) {
      // Fetch all executions because we don't know how many executions
      // happened while the socket was closed.
      handleGetExecutions();
      initializeExecutionSocket();
    } else {
      closeExecutionSocket();
    }
    return () => {
      closeExecutionSocket();
    };
  }, [realTimeExecutions]);

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <Header loading={loading} />
        </Card.Header>
        <Card.Body className="p-0">
          {loading && <Loading />}
          {!loading && agentExecutions.length === 0 && (
            <Alert key={'info'} variant={'info'} className="mx-3">
              Agent has no executions yet.
            </Alert>
          )}
          {!loading && agentExecutions.length > 0 && (
            <Table
              columns={getColumns(
                handleViewLogsClick,
                handleViewReturnValueClick
              )}
              data={agentExecutions}
            />
          )}
        </Card.Body>
      </Card>
      <TerminalModal
        content={terminalModalContent}
        show={showTerminalModal}
        onHide={handleCloseTerminalModal}
      />
    </>
  );
};

export default Executions;
