import styled from '@emotion/styled';
import { useAppContext } from 'contexts/AppContext';
import React, { useMemo, useState } from 'react';
import { Row, Col, Card, Placeholder, Button } from 'react-bootstrap';

const createCardWrapper = (isDark, cardIsCollapsed) =>
  styled.div({
    '& .body-text-wrapper, & .body-text-wrapper > *': {
      fontSize: '14px'
    },
    '& .body-text-wrapper': {
      maxHeight: 'calc(100vh - 280px)',
      overflowY: 'auto',
      '@media screen and (max-width: 991px)': {
        maxHeight: cardIsCollapsed ? '380px' : '100px',
        overflowY: cardIsCollapsed ? 'auto' : 'hidden'
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: '10px'
      },
      '&::-webkit-scrollbar': { width: '6px' },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '10px',
        backgroundColor: isDark ? '#b8b6b6' : '#a4a4a4'
      }
    },
    '& .body-more-wrapper': {
      textAlign: 'center',
      paddingTop: '20px',
      display: 'none',
      '@media screen and (max-width: 991px)': {
        display: 'block'
      }
    }
  });

const PanelBody = ({ loading, agentTemplate }) => {
  const [cardIsCollapsed, setCardIsCollapsed] = useState(false);
  const { isDark } = useAppContext();
  const CardWrapper = useMemo(
    () => createCardWrapper(isDark, cardIsCollapsed),
    [isDark, cardIsCollapsed]
  );
  const toggle = () => {
    setCardIsCollapsed(!cardIsCollapsed);
  };
  if (loading) {
    return (
      <>
        <hr className="my-0" />
        <Card.Body>
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={3} size="lg" />
            <Placeholder xs={12} />
            <Placeholder xs={12} />
            <Placeholder xs={12} />
            <Placeholder xs={12} />
            <Placeholder xs={12} />
          </Placeholder>
        </Card.Body>
      </>
    );
  }

  if (!agentTemplate.description) return null;

  return (
    <>
      <hr className="my-0" />
      <CardWrapper>
        <Card.Body>
          <Row className="d-flex justify-content-between">
            <Col xs={12}>
              <h5 className="fs-2 mb-3 d-flex align-items-center">
                {agentTemplate.name}
              </h5>
            </Col>
            <Col xs={12}>
              <div
                dangerouslySetInnerHTML={{
                  __html: agentTemplate.description.replace(/\n/g, '<br>')
                }}
                className="body-text-wrapper"
              />
              <div className="body-more-wrapper">
                <Button variant="falcon-primary" size="sm" onClick={toggle}>
                  {!cardIsCollapsed ? 'Show More' : 'Show Less'}
                </Button>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </CardWrapper>
    </>
  );
};

export default PanelBody;
