import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Error404 from 'components/common/Error404';
import { deleteQdrant, getQdrant } from 'helpers/requests/vector-stores';
import { appPaths } from 'config';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import {
  Button,
  Card,
  InputGroup,
  FormControl,
  Form,
  Row,
  Col
} from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Panel from './Panel';

const Qdrant = () => {
  const [loading, setLoading] = useState(true);
  const [qdrant, setQdrant] = useState({});
  const [notFound, setNotFound] = useState(false);
  const { id } = useParams();
  const isMounted = useRef(true);
  const navigate = useNavigate();

  let handleGetQdrant = async () => {
    const r = await getQdrant(id);
    if (r.success) {
      setQdrant(r.response.data);
    } else {
      if (r.response.status === 404) {
        setNotFound(true);
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
    setLoading(false);
  };

  const handleDeleteQdrant = async () => {
    if (window.confirm('Are you sure you want to delete this vector store?')) {
      const r = await deleteQdrant(id);
      if (r.success) {
        if (isMounted.current) {
          navigate(appPaths.vectorStores.qdrant.list);
        }
        toast.success('Vector store deleted successfully.');
      } else {
        if (r.response.status === 404) {
          toast.error('Vector store not found. Maybe it was already deleted?');
        } else {
          handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
        }
      }
    }
  };

  useEffect(() => {
    handleGetQdrant();
  }, []);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  if (notFound) {
    return <Error404 />;
  }

  const getMaskedAPIKey = key => {
    const visibleCharCount = 8;
    const firstFour = key.substring(0, visibleCharCount);
    const restLen = key.substring(visibleCharCount, key.length).length;
    return firstFour + '*'.repeat(restLen);
  };

  return (
    <>
      <Panel
        loading={loading}
        qdrant={qdrant}
        handleDeleteQdrant={handleDeleteQdrant}
      />
      {!loading && (
        <>
          <Card className="mb-3">
            <Card.Body>
              <Row>
                <Col lg={6}>
                  <Form.Label htmlFor="basic-url">Endpoint</Form.Label>
                  <InputGroup className="mb-3">
                    <CopyToClipboard
                      text={qdrant.url}
                      onCopy={() => toast.success('Copied to clipboard')}
                    >
                      <Button
                        variant="falcon-primary"
                        size="sm"
                        id="button-addon1"
                      >
                        <FontAwesomeIcon icon="copy" className="me-1" />
                        Copy
                      </Button>
                    </CopyToClipboard>
                    <FormControl
                      aria-label="Example text with button addon"
                      aria-describedby="basic-addon1"
                      value={qdrant.url}
                      disabled
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="mb-3">
            <Card.Body>
              <Row>
                <Col lg={6}>
                  <Form.Label htmlFor="basic-url">API Key</Form.Label>
                  <InputGroup className="mb-3">
                    <CopyToClipboard
                      text={qdrant.api_key}
                      onCopy={() => toast.success('Copied to clipboard')}
                    >
                      <Button
                        variant="falcon-primary"
                        size="sm"
                        id="button-addon1"
                      >
                        <FontAwesomeIcon icon="copy" className="me-1" />
                        Copy
                      </Button>
                    </CopyToClipboard>
                    <FormControl
                      aria-label="Example text with button addon"
                      aria-describedby="basic-addon1"
                      value={getMaskedAPIKey(qdrant.api_key)}
                      disabled
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};

export default Qdrant;
