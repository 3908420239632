import React, { useEffect, useRef } from 'react';
import { Card, Dropdown, Placeholder } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { appPaths } from 'config';
import { deleteAgent } from 'helpers/requests/agents';
import { handleUnexpectedStatusCode } from 'helpers/errors';

const PanelHeader = ({ loading, agent }) => {
  const navigate = useNavigate();
  const isMounted = useRef(true);

  const handleDeleteAgent = async () => {
    if (window.confirm('Are you sure you want to delete this agent?')) {
      const r = await deleteAgent(agent.id);
      if (r.success) {
        if (isMounted.current) {
          navigate(appPaths.agents.list);
        }
        toast.success('Agent deleted.');
      } else {
        if (r.response.status === 404) {
          toast.error('Agent not found. Maybe it was deleted?');
        } else {
          handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
        }
      }
    }
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  if (loading) {
    return (
      <Card.Header>
        <Placeholder as={Card.Text} animation="glow">
          <Placeholder xs={1} />
        </Placeholder>
      </Card.Header>
    );
  }

  return (
    <>
      <Card.Header className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <h5 className="fs-0 mb-0 d-flex align-items-center">{agent.name}</h5>
        </div>
        <div>
          <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
            <Dropdown.Toggle split variant="falcon-primary" size="sm">
              <FontAwesomeIcon icon="ellipsis-h" className="fs--2" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="border py-0">
              <div className="py-2">
                <Dropdown.Item
                  className="text-primary"
                  as={Link}
                  to={appPaths.stores.detail.replace(':id', agent.store_id)}
                >
                  View store
                </Dropdown.Item>
                <Dropdown.Item
                  className="text-primary"
                  as={Link}
                  to={appPaths.agents.update.replace(':id', agent.id)}
                >
                  Edit agent
                </Dropdown.Item>
                <Dropdown.Item
                  className="text-danger"
                  as={Link}
                  onClick={handleDeleteAgent}
                >
                  Delete agent
                </Dropdown.Item>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Card.Header>
    </>
  );
};

export default PanelHeader;
