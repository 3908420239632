import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthContext } from 'contexts/AuthContext';
import { appPaths } from 'config';
import queryString from 'query-string';

// Intended for pages that only authenticated users can access
export function PrivateRoute({ element }) {
  let { isAuthenticated } = useAuthContext();
  if (isAuthenticated) return element;
  const location = useLocation();
  const to = `${appPaths.auth.login}?next=${
    location.pathname + location.search
  }`;
  return <Navigate to={to} replace={true} />;
}

// Intended for pages that all users can access
export function PublicRoute({ element }) {
  return element;
}

// Intended for pages where users perform authentication actions.
// If the user is already authenticated, they will be navigated away.
export function AuthRoute({ element }) {
  let { isAuthenticated } = useAuthContext();
  if (!isAuthenticated) return element;

  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const to = queryParams.next || appPaths.index;
  return <Navigate to={to} replace={true} />;
}
