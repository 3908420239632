import { handleUnexpectedStatusCode } from 'helpers/errors';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CardSlider from './CardSlider';
import MblCardSlider from './MblCardSlider';

const ProductBlock = ({ getDataFunc, Card, block, link, heading }) => {
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(true);

  let handleGetData = async () => {
    const r = await getDataFunc();
    if (r.success) {
      setDataList(r.response.data);
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleGetData();
  }, []);
  return (
    <>
      <Row className="mb-3">
        <Col xs={6} md={6}>
          <h4>{heading}</h4>
        </Col>
        <Col xs={6} md={6} className="text-end">
          <Button
            className="btn-sm"
            as={Link}
            variant="primary"
            size="sm"
            to={link}
          >
            View all
          </Button>
        </Col>
      </Row>

      <Row className="d-none d-md-flex">
        <CardSlider
          dataList={dataList}
          Card={Card}
          block={block}
          loading={loading}
        />
      </Row>
      <Row className="d-md-none">
        <MblCardSlider
          dataList={dataList}
          Card={Card}
          block={block}
          loading={loading}
        />
      </Row>
    </>
  );
};

export default ProductBlock;
