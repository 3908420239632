import { createContext, useContext } from 'react';
import { settings } from 'config';

const AppContext = createContext(settings);

export const useAppContext = () => {
  const {
    config: {
      isFluid,
      isRTL,
      isDark,
      realTimeExecutions,
      codeEditorThemeDark,
      codeEditorThemeLight,
      disabledNavbarPosition,
      isNavbarVerticalCollapsed,
      navbarStyle,
      currency,
      showBurgerMenu,
      showSettingPanel,
      navbarCollapsed
    },
    setConfig
  } = useContext(AppContext);

  return {
    isFluid,
    isRTL,
    isDark,
    realTimeExecutions,
    codeEditorThemeDark,
    codeEditorThemeLight,
    disabledNavbarPosition,
    isNavbarVerticalCollapsed,
    navbarStyle,
    currency,
    showBurgerMenu,
    showSettingPanel,
    navbarCollapsed,
    setConfig
  };
};

export default AppContext;
