import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Error404 from 'components/common/Error404';
import Panel from 'components/bounties/bounty-details/panel/Panel';
import { getBounty } from 'helpers/requests/bounties';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import { useAuthContext } from 'contexts/AuthContext';

const Bounty = () => {
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [bounty, setBounty] = useState({});
  const [refresh, setRefresh] = useState(0);
  const { id } = useParams();
  const { isAuthenticated } = useAuthContext();

  let handleGetBounty = async () => {
    const r = await getBounty(id);
    if (r.success) {
      setBounty(r.response.data);
      setLoading(false);
    } else {
      if (r.response.status === 404) {
        setNotFound(true);
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
  };

  useEffect(() => {
    handleGetBounty();
  }, [refresh]);

  if (notFound) {
    return <Error404 />;
  }

  return (
    <Panel
      loading={loading}
      bounty={bounty}
      isAuthenticated={isAuthenticated}
      onRefresh={() => setRefresh(prev => prev + 1)}
    />
  );
};

export default Bounty;
