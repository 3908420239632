import { ax } from 'helpers/requests/axios';
import { apiPaths } from 'config';

export async function createBounty(
  name,
  description,
  amount_in_tokens,
  contact_information
) {
  const EXPECTED_STATUS_CODE = 201;

  try {
    const response = await ax.post(apiPaths.appBounties.bounties.create, {
      name: name,
      description: description,
      amount_in_tokens: amount_in_tokens,
      contact_information: contact_information
    });
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getBounty(id) {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appBounties.bounties.detail(id));
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getBounties() {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appBounties.bounties.list);
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function deleteBounty(id) {
  const EXPECTED_STATUS_CODE = 204;
  try {
    const response = await ax.delete(apiPaths.appBounties.bounties.delete(id));
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function updateBounty(id, payload) {
  const EXPECTED_STATUS_CODE = 204;
  const { name, description, amount_in_tokens, contact_information, archived } =
    payload;

  try {
    const response = await ax.patch(apiPaths.appBounties.bounties.update(id), {
      ...(name !== undefined && { name: name }),
      ...(description !== undefined && { description: description }),
      ...(amount_in_tokens !== undefined && {
        amount_in_tokens: amount_in_tokens
      }),
      ...(contact_information !== undefined && {
        contact_information: contact_information
      }),
      ...(archived !== undefined && { archived: archived })
    });
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function archiveBounty(id) {
  return await updateBounty(id, { archived: true });
}

export async function unarchiveBounty(id) {
  return await updateBounty(id, { archived: false });
}

export async function createBountyApplication(
  bountyId,
  contactInformation,
  proposedAmountInTokens
) {
  const EXPECTED_STATUS_CODE = 201;
  try {
    const response = await ax.post(
      apiPaths.appBounties.bountyApplications.create,
      {
        bounty_id: bountyId,
        contact_information: contactInformation,
        ...(proposedAmountInTokens !== undefined && {
          proposed_amount_in_tokens: proposedAmountInTokens
        })
      }
    );
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}
