import { createContext, useContext } from 'react';

const AuthContext = createContext();

export const useAuthContext = () => {
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);

  return {
    isAuthenticated,
    setIsAuthenticated
  };
};

export default AuthContext;
