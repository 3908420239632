import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppContext } from 'contexts/AppContext';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import { updateUserPreferences } from 'helpers/requests/users';
import React from 'react';
import { Button, Col, Row, Tooltip } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const Header = ({ loading }) => {
  const { realTimeExecutions, setConfig } = useAppContext();

  const tooltip = props => (
    <Tooltip id="button-tooltip" {...props}>
      {realTimeExecutions
        ? 'Pause real-time updates'
        : 'Enable real-time updates'}
    </Tooltip>
  );

  let handleUpdateRealTimeExecutions = async () => {
    const r = await updateUserPreferences({
      realTimeExecutions: !realTimeExecutions
    });
    if (r.success) {
      setConfig('realTimeExecutions', !realTimeExecutions);
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
  };

  return (
    <Row className="d-flex justify-content-between">
      <Col>
        <h5 className="mb-0 fs-0">Executions</h5>
      </Col>
      <Col className="d-flex justify-content-end">
        {!loading && (
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={tooltip}
          >
            <Button
              variant="falcon-primary"
              size="sm"
              className="d-flex justify-content-center align-items-center"
              onClick={handleUpdateRealTimeExecutions}
            >
              {realTimeExecutions && <FontAwesomeIcon icon="pause" />}
              {!realTimeExecutions && <FontAwesomeIcon icon="play" />}
            </Button>
          </OverlayTrigger>
        )}
      </Col>
    </Row>
  );
};

export default Header;
