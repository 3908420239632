import React from 'react';
import { Card } from 'react-bootstrap';
import PanelHeader from 'components/agents/agent-details/panel/PanelHeader';
import PanelBody from 'components/agents/agent-details/panel/PanelBody';

const Panel = ({ loading, agent, agentId, setNewCronJob }) => {
  return (
    <Card className="mb-3">
      <PanelHeader loading={loading} agent={agent} />
      <hr className="my-0" />
      <PanelBody
        loading={loading}
        agent={agent}
        agentId={agentId}
        setNewCronJob={setNewCronJob}
      />
    </Card>
  );
};

export default Panel;
