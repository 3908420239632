import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { Placeholder } from 'react-bootstrap';

const LoadingAgentTemplateCard = () => {
  return (
    <Col md={6} xl={4} xxl={3} className="mb-3">
      <Card className="h-100">
        <Card.Header>
          <Row>
            <Col xs={9}>
              <Card.Title>
                <small>
                  <Placeholder as={Card.Text} animation="glow">
                    <Placeholder xs={4} />
                  </Placeholder>
                </small>
              </Card.Title>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="py-1">
          <small>
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={12} />
              <Placeholder xs={12} />
              <Placeholder xs={12} />
            </Placeholder>
          </small>
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
    </Col>
  );
};

const Loading = () => {
  return (
    <>
      {[...Array(12)].map((_, index) => (
        <LoadingAgentTemplateCard key={index} />
      ))}
    </>
  );
};

export default Loading;
