import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, Form, Row, Col, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { setFormErrors } from 'helpers/errors';
import { createAgent } from 'helpers/requests/agents';
import { getStores } from 'helpers/requests/stores';
import { appPaths } from 'config';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import CodeEditorInForm from 'components/common/code-editor/CodeEditorInForm';

const CreateAgentForm = ({ agentTemplate }) => {
  const [submittingForm, setSubmittingForm] = useState(false);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [storesAreLoaded, setStoresAreLoaded] = useState(false);
  const isMounted = useRef(true);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    setError
  } = useForm();
  const navigate = useNavigate();

  let handleGetStores = async () => {
    const r = await getStores();
    if (r.success) {
      const returnedStores = r.response.data;
      const firstOption = returnedStores.length
        ? returnedStores.find(store => store.default) || returnedStores[0]
        : null;
      setSelectedStore(firstOption);
      if (firstOption !== null) {
        setValue('store_id', firstOption.id);
      }
      setStores(returnedStores);
      setStoresAreLoaded(true);
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
  };
  const reloadStores = async () => {
    if (storesAreLoaded) {
      setStoresAreLoaded(false);
      await handleGetStores();
    }
  };
  useEffect(() => {
    handleGetStores();
  }, []);

  useEffect(() => {
    if (agentTemplate !== null) {
      setValue('name', agentTemplate.name);
      setValue('script', agentTemplate.script);
      setValue('requirements', agentTemplate.requirements);
      setValue('env_vars', agentTemplate.env_vars);
      setValue('python_version', agentTemplate.python_version);
    }
  }, [agentTemplate]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const submitForm = async data => {
    setSubmittingForm(true);

    const r = await createAgent(
      data.name,
      data.python_version,
      data.script,
      data.requirements,
      data.env_vars,
      data.store_id
    );

    if (r.success) {
      if (isMounted.current) {
        navigate(appPaths.agents.detail.replace(':id', r.response.data.id));
      }
      toast.success('Agent created successfully.');
    } else {
      if (r.response.status === 400) {
        setFormErrors(r.response.data, setError);
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
    setSubmittingForm(false);
  };

  const startingScript = `def main(request, store):
    pass`;

  useEffect(() => {
    setValue('script', startingScript);
  }, [setValue]);

  return (
    <>
      <Form noValidate onSubmit={handleSubmit(submitForm)}>
        <Card className="mb-3 bg-light">
          <Card.Body>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} md={6} sm={12}>
                <Form.Label>Name*</Form.Label>
                <Form.Control
                  placeholder="Name"
                  name="name"
                  type="text"
                  isInvalid={!!errors.name}
                  {...register('name', {
                    required: 'Enter name'
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md={6} sm={12}>
                <Form.Label>Python version*</Form.Label>
                <Form.Select
                  name="python_version"
                  defaultValue="3.11"
                  isInvalid={!!errors.python_version}
                  {...register('python_version', {
                    required: 'Choose Python version'
                  })}
                >
                  <option value="3.9">3.9</option>
                  <option value="3.10">3.10</option>
                  <option value="3.11">3.11</option>
                  <option value="3.12">3.12</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.python_version?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Card.Body>
        </Card>

        <Card className="mb-3 bg-light">
          <Card.Body>
            <Row className="mb-3 g-3">
              <Form.Label>Script*</Form.Label>
              <CodeEditorInForm
                name="script"
                control={control}
                rules={{ required: 'Script is required.' }}
                language="python"
                height="70vh"
              />
            </Row>
          </Card.Body>
        </Card>

        <Card className="mb-3 bg-light">
          <Card.Body>
            <Row className="mb-3 g-3">
              <Form.Label>Requirements</Form.Label>
              <CodeEditorInForm
                name="requirements"
                control={control}
                language="plaintext"
                height="15vh"
              />
            </Row>
          </Card.Body>
        </Card>

        <Card className="mb-3 bg-light">
          <Card.Body>
            <Row className="mb-3 g-3">
              <Form.Label>Environment variables</Form.Label>
              <CodeEditorInForm
                name="env_vars"
                control={control}
                language="plaintext"
                height="15vh"
              />
            </Row>
          </Card.Body>
        </Card>

        <Card className="mb-3 bg-light">
          <Card.Body>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} md={6} sm={12}>
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Label>Store*</Form.Label>
                  {storesAreLoaded && (
                    <Button
                      variant="link"
                      className="p-0 link-secondary"
                      onClick={reloadStores}
                    >
                      <small>Reload</small>
                    </Button>
                  )}
                  {!storesAreLoaded && (
                    <Button variant="link" className="p-0 link-secondary">
                      <small>Loading...</small>
                    </Button>
                  )}
                </div>
                <Form.Select
                  name="store_id"
                  isInvalid={!!errors.store_id}
                  {...register('store_id', {
                    required: 'Choose a store'
                  })}
                  disabled={!storesAreLoaded}
                >
                  {/* selectedStore must be first. Otherwise, what react hook form
                  deems selected and and what the user thinks is selected may differ. */}
                  {selectedStore !== null && (
                    <option key={selectedStore.id} value={selectedStore.id}>
                      {selectedStore.name}
                    </option>
                  )}
                  {stores
                    .filter(
                      store =>
                        selectedStore === null || store.id !== selectedStore.id
                    )
                    .map(store => (
                      <option key={store.id} value={store.id}>
                        {store.name}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.store_id?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Card.Body>
        </Card>

        {errors.root?.nonFieldError?.message && (
          <Alert variant="danger">{errors.root.nonFieldError.message}</Alert>
        )}

        <Card>
          <Card.Body>
            <Form.Group className="d-flex justify-content-end">
              <Button type="submit" disabled={submittingForm}>
                Create
              </Button>
            </Form.Group>
          </Card.Body>
        </Card>
      </Form>
    </>
  );
};

export default CreateAgentForm;
