import React, { useEffect, useState } from 'react';
import { getBounties } from 'helpers/requests/bounties';
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import Loading from 'components/bounties/bounty-list/Loading';
import BountyCard from 'components/bounties/bounty-list/BountyCard';
import { useAuthContext } from 'contexts/AuthContext';
import { Link } from 'react-router-dom';
import { appPaths } from 'config';

const Bounties = () => {
  const ALL_BOUNTIES = 'all';
  const MY_BOUNTIES = 'my';
  const DEFAULT_FILTER = ALL_BOUNTIES;

  const [bountyList, setBountyList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(DEFAULT_FILTER);
  const { isAuthenticated } = useAuthContext();

  let handleGetBounties = async () => {
    const r = await getBounties();
    if (r.success) {
      setBountyList(r.response.data);
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleGetBounties();
  }, []);

  const handleFilterChange = event => {
    setFilter(event.target.value);
  };

  const filteredBounties = bountyList.filter(bounty => {
    if (filter === ALL_BOUNTIES) return true;
    return filter === MY_BOUNTIES && bounty.owner;
  });

  return (
    <>
      {isAuthenticated ? (
        <>
          <Card className="mb-3">
            <Card.Header>
              <Row>
                <Col
                  xs={4}
                  sm="auto"
                  className="d-flex align-items-center pe-0"
                >
                  <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                    Bounties
                  </h5>
                </Col>
                <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                  <Button
                    variant="primary"
                    size="sm"
                    as={Link}
                    to={appPaths.bounties.create}
                  >
                    Create
                  </Button>
                </Col>
              </Row>
            </Card.Header>
          </Card>
          <Row className="mb-3">
            <Col xs={12} className="d-flex justify-content-end">
              <Form.Group className="d-inline-block">
                <Form.Select
                  size="sm"
                  disabled={loading}
                  onChange={handleFilterChange}
                  defaultValue={DEFAULT_FILTER}
                >
                  <option value={ALL_BOUNTIES}>All bounties</option>
                  <option value={MY_BOUNTIES}>My bounties</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <div className="text-center mb-1 py-5">
            <h3>Bounties</h3>
            <p>
              Post your idea for an AI agent, set a bounty and let SwarmNode
              developers build it.
            </p>
          </div>
        </>
      )}
      <Row>
        {loading && <Loading />}
        {!loading && (
          <>
            {filteredBounties.map((bounty, index) => (
              <Col xl={6} className="mb-3" key={index}>
                <BountyCard bounty={bounty} />
              </Col>
            ))}
          </>
        )}
      </Row>
    </>
  );
};

export default Bounties;
