import React, { useEffect, useState } from 'react';
import { getAgentTemplates } from 'helpers/requests/agent-templates';
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import Loading from 'components/agent-templates/agent-template-list/Loading';
import AgentTemplateCard from 'components/agent-templates/agent-template-list/AgentTemplateCard';
import { useAuthContext } from 'contexts/AuthContext';
import { Link } from 'react-router-dom';
import { appPaths } from 'config';
import { getUseTemplateUrl } from 'components/agent-templates/helpers';

const AgentTemplates = () => {
  const ALL_TEMPLATES = 'all';
  const MY_TEMPLATES = 'my';
  const DEFAULT_FILTER = ALL_TEMPLATES;

  const [agentTemplateList, setAgentTemplateList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(DEFAULT_FILTER);
  const { isAuthenticated } = useAuthContext();

  let handleGetAgentTemplates = async () => {
    const r = await getAgentTemplates();
    if (r.success) {
      setAgentTemplateList(r.response.data);
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleGetAgentTemplates();
  }, []);

  const handleFilterChange = event => {
    setFilter(event.target.value);
  };

  const filteredAgentTemplates = agentTemplateList.filter(template => {
    if (filter === ALL_TEMPLATES) return true;
    return filter === MY_TEMPLATES && template.owner;
  });

  return (
    <>
      {isAuthenticated ? (
        <>
          <Card className="mb-3">
            <Card.Header>
              <Row>
                <Col
                  xs={4}
                  sm="auto"
                  className="d-flex align-items-center pe-0"
                >
                  <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                    Agent Templates
                  </h5>
                </Col>
                <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                  <Button
                    variant="primary"
                    size="sm"
                    as={Link}
                    to={appPaths.agentTemplates.create}
                  >
                    Create
                  </Button>
                </Col>
              </Row>
            </Card.Header>
          </Card>
          <Row className="mb-3">
            <Col xs={12} className="d-flex justify-content-end">
              <Form.Group className="d-inline-block">
                <Form.Select
                  size="sm"
                  disabled={loading}
                  onChange={handleFilterChange}
                  defaultValue={DEFAULT_FILTER}
                >
                  <option value={ALL_TEMPLATES}>All templates</option>
                  <option value={MY_TEMPLATES}>My templates</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <div className="text-center mb-1 py-5">
            <h3>Agent Templates</h3>
            <p>Save time by using pre-made agent templates</p>
          </div>
        </>
      )}
      <Row>
        {loading && <Loading />}
        {!loading && (
          <>
            {filteredAgentTemplates.map((agentTemplate, index) => (
              <Col md={6} xl={4} xxl={3} className="mb-3" key={index}>
                <AgentTemplateCard
                  agentTemplate={agentTemplate}
                  useTemplateUrl={getUseTemplateUrl(
                    agentTemplate.id,
                    isAuthenticated
                  )}
                />
              </Col>
            ))}
          </>
        )}
      </Row>
    </>
  );
};

export default AgentTemplates;
