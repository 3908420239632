import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { Button, Form, Row, Alert } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { setFormErrors } from 'helpers/errors';
import { createBountyApplication } from 'helpers/requests/bounties';
import { handleUnexpectedStatusCode } from 'helpers/errors';

const CreateBountyApplication = ({ bountyId, onSuccess }) => {
  const [submittingForm, setSubmittingForm] = useState(false);
  const isMounted = useRef(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const submitForm = async data => {
    setSubmittingForm(true);

    const r = await createBountyApplication(
      bountyId,
      data.contact_information,
      data.proposed_amount_in_tokens
    );

    if (r.success) {
      onSuccess();
      toast.success('Application was created successfully.');
    } else {
      if (r.response.status === 400) {
        setFormErrors(r.response.data, setError);
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
    setSubmittingForm(false);
  };

  return (
    <>
      <Form noValidate onSubmit={handleSubmit(submitForm)}>
        <Row className="mb-3 g-3">
          <Form.Group className="mb-3">
            <Form.Control
              name="contact_information"
              isInvalid={!!errors.contact_information}
              {...register('contact_information', {
                required: 'Enter contact information'
              })}
              as="textarea"
              rows={4}
            />
            <Form.Text className="text-muted">
              Indicate how you can be contacted (Discord, Telegram, email, etc.)
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              {errors.contact_information?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Proposed Amount in Tokens</Form.Label>
            <Form.Control
              type="number"
              name="proposed_amount_in_tokens"
              isInvalid={!!errors.proposed_amount_in_tokens}
              {...register('proposed_amount_in_tokens', {
                validate: value =>
                  !value ||
                  Number.isInteger(Number(value)) ||
                  'Bounty amount must be a whole number',
                valueAsNumber: true
              })}
            />
            <Form.Text className="text-muted">
              If the bounty amount is not enough, you can propose a higher
              amount. If not, you can leave this field empty.
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              {errors.proposed_amount_in_tokens?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        {errors.root?.nonFieldError?.message && (
          <Alert variant="danger">{errors.root.nonFieldError.message}</Alert>
        )}

        <Form.Group className="d-flex justify-content-end">
          <Button type="submit" disabled={submittingForm}>
            Create
          </Button>
        </Form.Group>
      </Form>
    </>
  );
};

export default CreateBountyApplication;
