import styled from '@emotion/styled';
import { getUseTemplateUrl } from 'components/agent-templates/helpers';
import { useAppContext } from 'contexts/AppContext';
import { useAuthContext } from 'contexts/AuthContext';
import React, { useMemo } from 'react';
import { Carousel, Col, Row } from 'react-bootstrap';
import LeftArrowBlack from '../../assets/img/icons/left-arrow-black.svg';
import LeftArrowWhite from '../../assets/img/icons/left-arrow-white.svg';
import RightArrowBlack from '../../assets/img/icons/right-arrow-black.svg';
import RightArrowWhite from '../../assets/img/icons/right-arrow-white.svg';
import Loading from './Loading';

const createCarouselWrapper = isDark =>
  styled.div({
    '& .carousel-control-prev': {
      right: 'calc(50% + 20px)',
      left: 'unset',
      top: 'unset',
      bottom: '-60px',
      height: 'fit-content',
      width: '20px',
      '& .carousel-control-prev-icon': {
        backgroundImage: `url(${isDark ? LeftArrowWhite : LeftArrowBlack})`
      }
    },
    '& .carousel-control-next': {
      left: 'calc(50% + 20px)',
      top: 'unset',
      bottom: '-60px',
      height: 'fit-content',
      width: '20px',
      '& .carousel-control-next-icon': {
        backgroundImage: `url(${isDark ? RightArrowWhite : RightArrowBlack})`
      }
    },
    '& .carousel-indicators': {
      display: 'none'
    }
  });

const CardWrapper = styled.div({
  '& .card-wrapper': {
    aspectRatio: '1'
  }
});

const MblCardSlider = ({ dataList, Card, block, loading }) => {
  const { isDark } = useAppContext();
  const { isAuthenticated } = useAuthContext();
  const CarouselWrapper = useMemo(
    () => createCarouselWrapper(isDark),
    [isDark]
  );
  if (loading) {
    return <Loading size={1} />;
  }
  return (
    <CarouselWrapper>
      <Carousel interval={null} controls={dataList.length > 1 ? true : false}>
        {dataList.map((cardData, index) => {
          const args =
            block === 'bounty'
              ? { bounty: cardData }
              : {
                  agentTemplate: cardData,
                  useTemplateUrl: getUseTemplateUrl(
                    cardData.id,
                    isAuthenticated
                  )
                };
          return (
            <Carousel.Item className="ps-2 pe-2" key={index}>
              <Row>
                <Col xs={12}>
                  <CardWrapper>
                    <Card {...args} className="card-wrapper" />
                  </CardWrapper>
                </Col>
              </Row>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </CarouselWrapper>
  );
};

export default MblCardSlider;
