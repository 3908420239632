import React from 'react';
import { Card } from 'react-bootstrap';
import PanelHeaderAuthenticated from 'components/bounties/bounty-details/panel/PanelHeaderAuthenticated';
import PanelHeaderUnauthenticated from 'components/bounties/bounty-details/panel/PanelHeaderUnauthenticated';
import PanelBody from 'components/bounties/bounty-details/panel/PanelBody';

const Panel = ({ loading, bounty, isAuthenticated, onRefresh }) => {
  return (
    <Card className="mb-3">
      {isAuthenticated ? (
        <PanelHeaderAuthenticated
          loading={loading}
          bounty={bounty}
          onRefresh={onRefresh}
        />
      ) : (
        <PanelHeaderUnauthenticated loading={loading} bounty={bounty} />
      )}
      <PanelBody loading={loading} bounty={bounty} />
    </Card>
  );
};

export default Panel;
