import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, Form, Row, Col, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { setFormErrors } from 'helpers/errors';
import { updateAgent } from 'helpers/requests/agents';
import { getStores } from 'helpers/requests/stores';
import { appPaths } from 'config';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import CodeEditorInForm from 'components/common/code-editor/CodeEditorInForm';

const UpdateAgentForm = ({ agent }) => {
  const [submittingForm, setSubmittingForm] = useState(false);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [storesAreLoaded, setStoresAreLoaded] = useState(false);
  const isMounted = useRef(true);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    setError
  } = useForm({
    defaultValues: {
      name: agent.name,
      script: agent.script,
      requirements: agent.requirements,
      env_vars: agent.env_vars,
      python_version: agent.python_version
    }
  });
  const navigate = useNavigate();

  let handleGetStores = async () => {
    const r = await getStores();
    if (r.success) {
      const returnedStores = r.response.data;
      const agentStore = returnedStores.find(
        store => store.id === agent.store_id
      );
      setSelectedStore(agentStore);
      setValue('store_id', agentStore.id);
      setStores(returnedStores);
      setStoresAreLoaded(true);
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
  };
  const reloadStores = async () => {
    if (storesAreLoaded) {
      setStoresAreLoaded(false);
      await handleGetStores();
    }
  };
  useEffect(() => {
    handleGetStores();
  }, []);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const agentNeedsRebuilding = data => {
    return (
      agent.python_version !== data.python_version ||
      agent.requirements !== data.requirements
    );
  };

  const submitForm = async data => {
    const rebuildMsg = `To save the changes you made, the agent will need to be rebuilt.

Do you want to continue?`;
    if (agentNeedsRebuilding(data) && !window.confirm(rebuildMsg)) return;

    setSubmittingForm(true);

    const r = await updateAgent(agent.id, {
      name: data.name,
      python_version: data.python_version,
      script: data.script,
      requirements: data.requirements,
      env_vars: data.env_vars,
      store_id: data.store_id
    });

    if (r.success) {
      if (isMounted.current) {
        navigate(appPaths.agents.detail.replace(':id', agent.id));
      }
      toast.success('Agent edited successfully.');
    } else {
      if (r.response.status === 400) {
        setFormErrors(r.response.data, setError);
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
    setSubmittingForm(false);
  };

  return (
    <>
      <Form noValidate onSubmit={handleSubmit(submitForm)}>
        <Card className="mb-3 bg-light">
          <Card.Body>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} md={6} sm={12}>
                <Form.Label>Name*</Form.Label>
                <Form.Control
                  placeholder="Name"
                  name="name"
                  type="text"
                  isInvalid={!!errors.name}
                  {...register('name', {
                    required: 'Enter name.'
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md={6} sm={12}>
                <Form.Label>Python version*</Form.Label>
                <Form.Select
                  name="python_version"
                  isInvalid={!!errors.python_version}
                  {...register('python_version', {
                    required: 'Choose Python version'
                  })}
                >
                  <option value="3.9">3.9</option>
                  <option value="3.10">3.10</option>
                  <option value="3.11">3.11</option>
                  <option value="3.12">3.12</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.python_version?.message}
                </Form.Control.Feedback>
                <Form.Text muted>
                  Changing the Python version will cause the agent to be
                  rebuilt.
                </Form.Text>
              </Form.Group>
            </Row>
          </Card.Body>
        </Card>

        <Card className="mb-3 bg-light">
          <Card.Body>
            <Row className="mb-3 g-3">
              <Form.Label>Script*</Form.Label>
              <CodeEditorInForm
                name="script"
                control={control}
                rules={{ required: 'Script is required.' }}
                language="python"
                height="70vh"
              />
            </Row>
          </Card.Body>
        </Card>

        <Card className="mb-3 bg-light">
          <Card.Body>
            <Row className="mb-3 g-3">
              <Form.Label>Requirements</Form.Label>
              <CodeEditorInForm
                name="requirements"
                control={control}
                language="plaintext"
                height="15vh"
              />
              <Form.Text className="mt-2" muted>
                Changing the requirements will cause the agent to be rebuilt.
              </Form.Text>
            </Row>
          </Card.Body>
        </Card>

        <Card className="mb-3 bg-light">
          <Card.Body>
            <Row className="mb-3 g-3">
              <Form.Label>Environment variables</Form.Label>
              <CodeEditorInForm
                name="env_vars"
                control={control}
                language="plaintext"
                height="15vh"
              />
            </Row>
          </Card.Body>
        </Card>

        <Card className="mb-3 bg-light">
          <Card.Body>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} md={6} sm={12}>
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Label>Store*</Form.Label>
                  {storesAreLoaded && (
                    <Button
                      variant="link"
                      className="p-0 link-secondary"
                      onClick={reloadStores}
                    >
                      <small>Reload</small>
                    </Button>
                  )}
                  {!storesAreLoaded && (
                    <Button variant="link" className="p-0 link-secondary">
                      <small>Loading...</small>
                    </Button>
                  )}
                </div>
                <Form.Select
                  name="store_id"
                  isInvalid={!!errors.store_id}
                  {...register('store_id', {
                    required: 'Choose a store'
                  })}
                  disabled={!storesAreLoaded}
                >
                  {/* selectedStore must be first. Otherwise, what react hook form
                  deems selected and and what the user thinks is selected may differ.
                  */}
                  {selectedStore !== null && (
                    <option key={selectedStore.id} value={selectedStore.id}>
                      {selectedStore.name}
                    </option>
                  )}
                  {stores
                    .filter(
                      store =>
                        selectedStore === null || store.id !== selectedStore.id
                    )
                    .map(store => (
                      <option key={store.id} value={store.id}>
                        {store.name}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.store_id?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Card.Body>
        </Card>

        {errors.root?.nonFieldError?.message && (
          <Alert variant="danger">{errors.root.nonFieldError.message}</Alert>
        )}

        <Card>
          <Card.Body>
            <Form.Group className="d-flex justify-content-end">
              <Button type="submit" disabled={submittingForm}>
                Save
              </Button>
            </Form.Group>
          </Card.Body>
        </Card>
      </Form>
    </>
  );
};

export default UpdateAgentForm;
