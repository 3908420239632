import React from 'react';
import { Col, Row, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { appPaths } from 'config';

const BountyCard = ({ bounty, className = '' }) => {
  const MAX_DESCRIPTION_LENGTH = 300;

  const shortenDescription = description => {
    // Replace newlines with spaces and collapse multiple spaces into one
    let ret = description.replace(/\s+/g, ' ');
    // Truncate if the length is greater than 100 characters
    if (ret.length > MAX_DESCRIPTION_LENGTH) {
      return ret.substring(0, MAX_DESCRIPTION_LENGTH - 3) + '...';
    }
    return ret;
  };

  const viewBountyUrl = appPaths.bounties.detail.replace(':id', bounty.id);

  return (
    <Card className={`h-100 ${className}`}>
      <Card.Header className="pb-0">
        <Row>
          <Col xs={12}>
            <Card.Title>
              <h5 className="mb-3 text-success">
                {bounty.amount_in_tokens} SNAI
              </h5>
              <Button
                variant="link text-dark"
                className="p-0 stretched-link"
                as={Link}
                to={viewBountyUrl}
              >
                {bounty.name}
              </Button>
            </Card.Title>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="py-1 pb-3">
        <Card.Text className="text-muted">
          <small>{shortenDescription(bounty.description)}</small>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default BountyCard;
