import React, { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { getAgents } from 'helpers/requests/agents';
import { getStores } from 'helpers/requests/stores';
import AgentsInfo from 'components/stats/generic/AgentsInfo';
import StoresInfo from 'components/stats/generic/StoresInfo';

const Generic = () => {
  const [agentCount, setAgentCount] = useState(0);
  const [storeCount, setStoreCount] = useState(0);

  const handleGetAgents = async () => {
    const r = await getAgents();
    if (r.success) {
      setAgentCount(r.response.data.length);
    }
  };

  const handleGetStores = async () => {
    const r = await getStores();
    if (r.success) {
      setStoreCount(r.response.data.length);
    }
  };

  useEffect(() => {
    handleGetAgents();
    handleGetStores();
  }, []);

  return (
    <Row className="g-3 mb-0">
      <AgentsInfo count={agentCount} />
      <StoresInfo count={storeCount} />
    </Row>
  );
};

export default Generic;
