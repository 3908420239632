import React from 'react';
import { Card, Dropdown, Placeholder } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const Panel = ({ loading, qdrant, handleDeleteQdrant }) => {
  if (loading) {
    return (
      <Card className="mb-3">
        <Card.Header>
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={2} />
          </Placeholder>
        </Card.Header>
      </Card>
    );
  } else {
    return (
      <Card className="mb-3">
        <Card.Header className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <h5 className="fs-0 mb-0 d-flex align-items-center">
              {qdrant.name}
            </h5>
          </div>
          <div>
            <Dropdown
              align="end"
              className="btn-reveal-trigger d-inline-block ms-2"
            >
              <Dropdown.Toggle split variant="falcon-default" size="sm">
                <FontAwesomeIcon icon="ellipsis-h" className="fs--2" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="border py-0">
                <div className="py-2">
                  <Dropdown.Item
                    className="text-danger"
                    as={Link}
                    onClick={handleDeleteQdrant}
                  >
                    Delete
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Card.Header>
      </Card>
    );
  }
};

export default Panel;
