import React, { useEffect, useRef, useState } from 'react';
import {
  Card,
  Dropdown,
  Placeholder,
  Button,
  Col,
  Row,
  Modal
} from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import {
  deleteBounty,
  archiveBounty,
  unarchiveBounty
} from 'helpers/requests/bounties';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import { appPaths } from 'config';
import SoftBadge from 'components/common/SoftBadge';
import CreateBountyApplication from 'components/bounties/bounty-details/CreateBountyApplication';

const PanelHeaderAuthenticated = ({ loading, bounty, onRefresh }) => {
  const navigate = useNavigate();
  const isMounted = useRef(true);
  const [showApplicationModal, setShowApplicationModal] = useState(false);
  const [statusMessage, setStatusMessage] = useState('Unknown status');
  const [statusColor, setStatusColor] = useState('warning');

  const handleDeleteBounty = async () => {
    if (window.confirm('Are you sure you want to delete this bounty?')) {
      const r = await deleteBounty(bounty.id);
      if (r.success) {
        if (isMounted.current) {
          navigate(appPaths.bounties.list);
        }
        toast.success('Bounty deleted.');
      } else {
        if (r.response.status === 404) {
          toast.error('Bounty not found. Maybe it was deleted?');
        } else {
          handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
        }
      }
    }
  };

  const handleArchiveBounty = async () => {
    if (!window.confirm('Are you sure you want to archive this bounty?')) {
      return;
    }
    const r = await archiveBounty(bounty.id);
    if (r.success) {
      onRefresh();
      toast.success('Bounty archived.');
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
  };

  const handleUnarchiveBounty = async () => {
    if (!window.confirm('Are you sure you want to unarchive this bounty?')) {
      return;
    }
    const r = await unarchiveBounty(bounty.id);
    if (r.success) {
      onRefresh();
      toast.success('Bounty unarchived.');
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (bounty) {
      if (bounty.archived) {
        setStatusMessage('Archived');
        setStatusColor('info');
      } else if (bounty.approved === null) {
        setStatusMessage('In review');
        setStatusColor('warning');
      } else if (bounty.approved === true) {
        setStatusMessage('Approved');
        setStatusColor('info');
      } else if (bounty.approved === false) {
        setStatusMessage('Not approved');
        setStatusColor('danger');
      }
    }
  }, [bounty, onRefresh]);

  if (loading) {
    return (
      <Card.Header>
        <Placeholder as={Card.Text} animation="glow">
          <Placeholder xs={1} />
        </Placeholder>
      </Card.Header>
    );
  }

  return (
    <Card.Header>
      <Row className="d-flex">
        <Col
          xs={6}
          className="d-flex justify-content-between align-items-center"
        >
          <h5 className="fs-0 mb-0 d-flex align-items-center text-success">
            {bounty.amount_in_tokens} SNAI
          </h5>
        </Col>
        <Col xs={6} className="d-flex align-items-center justify-content-end">
          {bounty.owner && (
            <SoftBadge bg={statusColor} className="mx-2">
              {statusMessage}
            </SoftBadge>
          )}
          {!bounty.owner && (
            <>
              <Button
                variant="primary"
                size="sm"
                onClick={() => setShowApplicationModal(true)}
              >
                Apply
              </Button>
              <Modal
                show={showApplicationModal}
                onHide={() => setShowApplicationModal(false)}
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Apply for Bounty</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <CreateBountyApplication
                    bountyId={bounty.id}
                    onSuccess={() => setShowApplicationModal(false)}
                  />
                </Modal.Body>
              </Modal>
            </>
          )}
          {bounty.owner && (
            <>
              <Dropdown
                align="end"
                className="btn-reveal-trigger d-inline-block"
              >
                <Dropdown.Toggle split variant="falcon-primary" size="sm">
                  <FontAwesomeIcon icon="ellipsis-h" className="fs--2" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="border py-0">
                  <div className="py-2">
                    <Dropdown.Item
                      className="text-primary"
                      as={Link}
                      to={appPaths.bounties.update.replace(':id', bounty.id)}
                    >
                      Edit bounty
                    </Dropdown.Item>
                    {bounty.archived === false && (
                      <Dropdown.Item
                        className="text-primary"
                        as={Link}
                        onClick={handleArchiveBounty}
                      >
                        Archive bounty
                      </Dropdown.Item>
                    )}
                    {bounty.archived === true && (
                      <Dropdown.Item
                        className="text-primary"
                        as={Link}
                        onClick={handleUnarchiveBounty}
                      >
                        Unarchive bounty
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      className="text-danger"
                      as={Link}
                      onClick={handleDeleteBounty}
                    >
                      Delete bounty
                    </Dropdown.Item>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}
        </Col>
      </Row>
    </Card.Header>
  );
};

export default PanelHeaderAuthenticated;
