import React, { useState, useRef, useEffect } from 'react';
import { Button, Card, Form, Row, Alert } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import CodeEditor from 'components/common/code-editor/CodeEditor';
import { toast } from 'react-toastify';
import { useAppContext } from 'contexts/AppContext';
import { handleUnexpectedStatusCode, setFormErrors } from 'helpers/errors';
import { updateUserPreferences } from 'helpers/requests/users';
import { useNavigate } from 'react-router-dom';
import { appPaths } from 'config';
import {
  getThemeObjectFromName,
  codeEdtiorThemes
} from 'components/common/code-editor/CodeEditor';

const UpdateUserPreferencesForm = () => {
  const { codeEditorThemeDark, codeEditorThemeLight, setConfig } =
    useAppContext();

  const [submittingForm, setSubmittingForm] = useState(false);
  const [darkTheme, setDarkTheme] = useState(codeEditorThemeDark);
  const [lightTheme, setLightTheme] = useState(codeEditorThemeLight);
  const isMounted = useRef(true);
  const navigate = useNavigate();
  const dummyCode = `def main(request, store):
  pass`;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError
  } = useForm({
    defaultValues: {
      code_editor_theme_dark: codeEditorThemeDark,
      code_editor_theme_light: codeEditorThemeLight
    }
  });

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const submitForm = async data => {
    setSubmittingForm(true);
    const r = await updateUserPreferences({
      codeEditorThemeDark: data.code_editor_theme_dark,
      codeEditorThemeLight: data.code_editor_theme_light
    });

    if (r.success) {
      if (isMounted.current) {
        navigate(appPaths.settings.profile);
      }
      setConfig('codeEditorThemeDark', data.code_editor_theme_dark);
      setConfig('codeEditorThemeLight', data.code_editor_theme_light);
      toast.success('Preferences edited successfully.');
    } else {
      if (r.response.status === 400) {
        setFormErrors(r.response.data, setError);
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
    setSubmittingForm(false);
  };
  return (
    <Form noValidate onSubmit={handleSubmit(submitForm)}>
      <Card className="mb-3 bg-light">
        <Card.Body>
          <Row className="mb-3 g-3">
            <Form.Label>Dark theme editor style</Form.Label>
            <Form.Select
              {...register('code_editor_theme_dark')}
              onChange={e => {
                setDarkTheme(e.target.value);
              }}
              isInvalid={!!errors.code_editor_theme_dark}
            >
              {codeEdtiorThemes &&
                codeEdtiorThemes.map(theme => (
                  <option value={theme.name} key={theme.name}>
                    {theme.displayName}
                  </option>
                ))}
            </Form.Select>
            <CodeEditor
              name="script"
              control={control}
              theme={getThemeObjectFromName(darkTheme)}
              value={dummyCode}
              readOnly={true}
              language="python"
              height="20vh"
            />
            <Form.Control.Feedback type="invalid">
              {errors.code_editor_theme_dark?.message}
            </Form.Control.Feedback>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mb-3 bg-light">
        <Card.Body>
          <Row className="mb-3 g-3">
            <Form.Label>Light theme editor style</Form.Label>
            <Form.Select
              {...register('code_editor_theme_light')}
              onChange={e => {
                setLightTheme(e.target.value);
              }}
              isInvalid={!!errors.code_editor_theme_light}
            >
              {codeEdtiorThemes &&
                codeEdtiorThemes.map(theme => (
                  <option value={theme.name} key={theme.name}>
                    {theme.displayName}
                  </option>
                ))}
            </Form.Select>
            <CodeEditor
              name="script"
              control={control}
              theme={getThemeObjectFromName(lightTheme)}
              value={dummyCode}
              readOnly={true}
              language="python"
              height="20vh"
            />
            <Form.Control.Feedback type="invalid">
              {errors.code_editor_theme_light?.message}
            </Form.Control.Feedback>
          </Row>
        </Card.Body>
      </Card>
      {errors.root?.nonFieldError?.message && (
        <Alert variant="danger">{errors.root.nonFieldError.message}</Alert>
      )}
      <Card>
        <Card.Body>
          <Form.Group className="d-flex justify-content-end">
            <Button type="submit" disabled={submittingForm}>
              Update
            </Button>
          </Form.Group>
        </Card.Body>
      </Card>
    </Form>
  );
};

export default UpdateUserPreferencesForm;
