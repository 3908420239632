import React from 'react';
import { Col, Row, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { appPaths } from 'config';

const AgentTemplateCard = ({
  agentTemplate,
  useTemplateUrl,
  className = ''
}) => {
  const MAX_DESCRIPTION_LENGTH = 150;

  const shortenDescription = description => {
    // Replace newlines with spaces and collapse multiple spaces into one
    let ret = description.replace(/\s+/g, ' ');
    // Truncate if the length is greater than 100 characters
    if (ret.length > MAX_DESCRIPTION_LENGTH) {
      return ret.substring(0, MAX_DESCRIPTION_LENGTH - 3) + '...';
    }
    return ret;
  };

  const viewTemplateUrl = appPaths.agentTemplates.detail.replace(
    ':id',
    agentTemplate.id
  );

  return (
    <Card className={`h-100 ${className}`}>
      <Card.Header>
        <Row>
          <Col xs={12}>
            <Card.Title>
              <Button
                variant="link"
                className="p-0"
                as={Link}
                to={viewTemplateUrl}
              >
                {agentTemplate.name}
              </Button>
            </Card.Title>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="py-1">
        <Card.Text className="text-muted">
          <small className="description">
            {shortenDescription(agentTemplate.description)}
          </small>
        </Card.Text>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-between">
        <Button
          as={Link}
          to={viewTemplateUrl}
          variant="link"
          size="sm"
          className="ps-0 view-btn"
        >
          View
        </Button>
        <Button
          as={Link}
          to={useTemplateUrl}
          variant="outline-primary"
          size="sm"
          className="use-template-btn"
        >
          Use Template
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default AgentTemplateCard;
