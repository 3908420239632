import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { deleteQdrant, getQdrants } from 'helpers/requests/vector-stores';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import Empty from 'components/vector-stores/qdrant/list/Empty';
import Loading from 'components/vector-stores/qdrant/list/Loading';
import getColumns from './get-columns';
import Table from './Table';
import Header from './Header';
import { toast } from 'react-toastify';

const Qdrants = () => {
  const [qdrantList, setQdrantList] = useState([]);
  const [loading, setLoading] = useState(true);

  const removeQdrant = id => {
    setQdrantList(prevQdrantList =>
      prevQdrantList.filter(qdrant => qdrant.id !== id)
    );
  };

  const handleDeleteQdrant = async id => {
    if (window.confirm('Are you sure you want to delete this vector store?')) {
      const r = await deleteQdrant(id);
      if (r.success) {
        removeQdrant(id);
        toast.success('Vector store deleted.');
      } else {
        if (r.response.status === 404) {
          toast.error('Vector store not found. Maybe it was already deleted?');
        } else {
          handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
        }
      }
    }
  };

  let handleGetQdrants = async () => {
    const r = await getQdrants();
    if (r.success) {
      setQdrantList(r.response.data);
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleGetQdrants();
  }, []);

  return (
    <Card className="mb-3">
      <Card.Header>
        <Header />
      </Card.Header>
      <Card.Body className="p-0">
        {loading && <Loading />}
        {!loading && qdrantList.length === 0 && <Empty />}
        {!loading && qdrantList.length > 0 && (
          <Table columns={getColumns(handleDeleteQdrant)} data={qdrantList} />
        )}
      </Card.Body>
    </Card>
  );
};

export default Qdrants;
