import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Error404 from 'components/common/Error404';
import Panel from 'components/agent-templates/agent-template-details/panel/Panel';
import Script from 'components/agents/agent-details/Script';
import EnvVars from 'components/agents/agent-details/EnvVars';
import Requirements from 'components/agents/agent-details/Requirements';
import { getAgentTemplate } from 'helpers/requests/agent-templates';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import CTA from 'components/agent-templates/agent-template-details/CTA';
import { useAuthContext } from 'contexts/AuthContext';
import { getUseTemplateUrl } from 'components/agent-templates/helpers';
import { Col, Row } from 'react-bootstrap';

const AgentTemplate = () => {
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [agentTemplate, setAgentTemplate] = useState({});
  const { id } = useParams();
  const { isAuthenticated } = useAuthContext();

  const useTemplateUrl = getUseTemplateUrl(id, isAuthenticated);

  let handleGetAgentTemplate = async () => {
    const r = await getAgentTemplate(id);
    if (r.success) {
      setAgentTemplate(r.response.data);
      setLoading(false);
    } else {
      if (r.response.status === 404) {
        setNotFound(true);
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
  };

  useEffect(() => {
    handleGetAgentTemplate();
  }, []);

  if (notFound) {
    return <Error404 />;
  }

  return (
    <>
      {!isAuthenticated && <CTA useTemplateUrl={useTemplateUrl} />}
      <Row className="flex-column-reverse flex-lg-row">
        <Col xs={12} lg={8} xxl={9}>
          {!loading && (
            <>
              <Script value={agentTemplate.script} isCollapsed={false} />
              <Requirements
                value={agentTemplate.requirements}
                isCollapsed={false}
              />
              <EnvVars value={agentTemplate.env_vars} isCollapsed={false} />
            </>
          )}
        </Col>
        <Col xs={12} lg={4} xxl={3} className="ps-lg-0">
          <Panel
            loading={loading}
            agentTemplate={agentTemplate}
            useTemplateUrl={useTemplateUrl}
            isAuthenticated={isAuthenticated}
          />
        </Col>
      </Row>
    </>
  );
};

export default AgentTemplate;
