import React, { useEffect, useRef } from 'react';
import { Card, Dropdown, Placeholder, Button, Col, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { deleteAgentTemplate } from 'helpers/requests/agent-templates';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import { appPaths } from 'config';
import SoftBadge from 'components/common/SoftBadge';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import styled from '@emotion/styled';

const CardWrapper = styled.div({
  '& .use-template': {
    fontSize: '12px',
    whiteSpace: 'nowrap',
    paddingLeft: '10px',
    paddingRight: '10px'
  }
});

const PanelHeaderAuthenticated = ({
  loading,
  agentTemplate,
  useTemplateUrl
}) => {
  const navigate = useNavigate();
  const isMounted = useRef(true);

  const handleDeleteAgentTemplate = async () => {
    if (window.confirm('Are you sure you want to delete this template?')) {
      const r = await deleteAgentTemplate(agentTemplate.id);
      if (r.success) {
        if (isMounted.current) {
          navigate(appPaths.agentTemplates.list);
        }
        toast.success('Template deleted.');
      } else {
        if (r.response.status === 404) {
          toast.error('Template not found. Maybe it was deleted?');
        } else {
          handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
        }
      }
    }
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  if (loading) {
    return (
      <Card.Header>
        <Placeholder
          as={Card.Text}
          animation="glow"
          className="d-flex align-items-center justify-content-end gap-2"
        >
          <Placeholder xs={3} size="lg" />
          <Placeholder xs={3} size="lg" />
          <Placeholder xs={3} size="lg" />
          <Placeholder xs={2} size="lg" />
        </Placeholder>
      </Card.Header>
    );
  }

  return (
    <CardWrapper>
      <Card.Header>
        <Row className="d-flex">
          <Col
            xs={12}
            className="d-flex align-items-stretch justify-content-between gap-2"
          >
            <div className="d-flex align-items-stretch justify-content-end gap-2">
              <Button
                variant="primary"
                size="sm"
                as={Link}
                to={useTemplateUrl}
                className="d-flex align-items-center justify-content-end gap-2 use-template"
              >
                Use Template
              </Button>
              <SoftBadge
                bg="success"
                className="py-2 px-2 d-flex align-items-center justify-content-end gap-1"
              >
                <FontAwesomeIcon icon={faHeart} className="" />
                132
              </SoftBadge>
              {agentTemplate.owner && (
                <SoftBadge bg="info" className="py-2 px-2">
                  {agentTemplate.public ? 'Public' : 'Private'}
                </SoftBadge>
              )}
            </div>
            <div className="d-flex align-items-stretch justify-content-end gap-2">
              {agentTemplate.owner && (
                <>
                  <Dropdown
                    align="end"
                    className="btn-reveal-trigger d-inline-block"
                  >
                    <Dropdown.Toggle split variant="falcon-primary" size="sm">
                      <FontAwesomeIcon icon="ellipsis-h" className="fs--2" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="border py-0">
                      <div className="py-2">
                        <Dropdown.Item
                          className="text-primary"
                          as={Link}
                          to={appPaths.agentTemplates.update.replace(
                            ':id',
                            agentTemplate.id
                          )}
                        >
                          Edit template
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="text-danger"
                          as={Link}
                          onClick={handleDeleteAgentTemplate}
                        >
                          Delete template
                        </Dropdown.Item>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Card.Header>
    </CardWrapper>
  );
};

export default PanelHeaderAuthenticated;
