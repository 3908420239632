import React from 'react';
import { Button } from 'react-bootstrap';
import { useAppContext } from 'contexts/AppContext';

const ToggleButton = () => {
  const { isNavbarVerticalCollapsed, setConfig } = useAppContext();

  const handleClick = () => {
    document
      .getElementsByTagName('html')[0]
      .classList.toggle('navbar-vertical-collapsed');
    setConfig('isNavbarVerticalCollapsed', !isNavbarVerticalCollapsed);
  };

  return (
    <div className="toggle-icon-wrapper">
      <Button
        variant="link"
        className="navbar-toggler-humburger-icon navbar-vertical-toggle"
        id="toggleNavigationTooltip"
        onClick={handleClick}
      >
        <span className="navbar-toggle-icon">
          <span className="toggle-line" />
        </span>
      </Button>
    </div>
  );
};

export default ToggleButton;
