import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, Form, Row, Col, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { setFormErrors } from 'helpers/errors';
import { createAgentTemplate } from 'helpers/requests/agent-templates';
import { appPaths } from 'config';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import CodeEditorInForm from 'components/common/code-editor/CodeEditorInForm';

const CreateAgentTemplateForm = () => {
  const [submittingForm, setSubmittingForm] = useState(false);
  const isMounted = useRef(true);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    setError
  } = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const submitForm = async data => {
    setSubmittingForm(true);

    const r = await createAgentTemplate(
      data.name,
      data.description,
      data.script,
      data.requirements,
      data.env_vars,
      data.python_version,
      data.public
    );

    if (r.success) {
      if (isMounted.current) {
        navigate(
          appPaths.agentTemplates.detail.replace(':id', r.response.data.id)
        );
      }
      toast.success('Agent template was created successfully.');
    } else {
      if (r.response.status === 400) {
        setFormErrors(r.response.data, setError);
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
    setSubmittingForm(false);
  };

  const startingScript = `def main(request, store):
    pass`;

  useEffect(() => {
    setValue('script', startingScript);
    setValue('public', true);
  }, [setValue]);

  return (
    <>
      <Form noValidate onSubmit={handleSubmit(submitForm)}>
        <Card className="mb-3 bg-light">
          <Card.Body>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} md={6} sm={12}>
                <Form.Label>Name*</Form.Label>
                <Form.Control
                  placeholder="Name"
                  name="name"
                  type="text"
                  isInvalid={!!errors.name}
                  {...register('name', {
                    required: 'Enter name'
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md={6} sm={12}>
                <Form.Label>Python version*</Form.Label>
                <Form.Select
                  name="python_version"
                  defaultValue="3.11"
                  isInvalid={!!errors.python_version}
                  {...register('python_version', {
                    required: 'Choose Python version'
                  })}
                >
                  <option value="3.9">3.9</option>
                  <option value="3.10">3.10</option>
                  <option value="3.11">3.11</option>
                  <option value="3.12">3.12</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.python_version?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Card.Body>
        </Card>
        <Card className="mb-3 bg-light">
          <Card.Body>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} sm={12}>
                <Form.Label>Access</Form.Label>
                <Form.Check
                  type="checkbox"
                  label="Public"
                  name="public"
                  isInvalid={!!errors.public}
                  className="mb-n1"
                  {...register('public')}
                />
                <Form.Text className="text-muted">
                  If you make the template public, it will be listed in the
                  public templates library and other users will be able to
                  create agents from it.
                </Form.Text>
              </Form.Group>
            </Row>
          </Card.Body>
        </Card>

        <Card className="mb-3 bg-light">
          <Card.Body>
            <Row className="mb-3 g-3">
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  name="description"
                  isInvalid={!!errors.description}
                  {...register('description', {})}
                  as="textarea"
                  rows={4}
                />
              </Form.Group>
            </Row>
          </Card.Body>
        </Card>

        <Card className="mb-3 bg-light">
          <Card.Body>
            <Row className="mb-3 g-3">
              <Form.Label>Script*</Form.Label>
              <CodeEditorInForm
                name="script"
                control={control}
                rules={{ required: 'Script is required.' }}
                language="python"
                height="70vh"
              />
            </Row>
          </Card.Body>
        </Card>

        <Card className="mb-3 bg-light">
          <Card.Body>
            <Row className="mb-3 g-3">
              <Form.Label>Requirements</Form.Label>
              <CodeEditorInForm
                name="requirements"
                control={control}
                language="plaintext"
                height="15vh"
              />
            </Row>
          </Card.Body>
        </Card>

        <Card className="mb-3 bg-light">
          <Card.Body>
            <Row className="mb-3 g-3">
              <Form.Label>Environment variables</Form.Label>
              <CodeEditorInForm
                name="env_vars"
                control={control}
                language="plaintext"
                height="15vh"
              />
            </Row>
          </Card.Body>
        </Card>

        {errors.root?.nonFieldError?.message && (
          <Alert variant="danger">{errors.root.nonFieldError.message}</Alert>
        )}

        <Card>
          <Card.Body>
            <Form.Group className="d-flex justify-content-end">
              <Button type="submit" disabled={submittingForm}>
                Create
              </Button>
            </Form.Group>
          </Card.Body>
        </Card>
      </Form>
    </>
  );
};

export default CreateAgentTemplateForm;
