import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import UpdateAgentTemplateForm from 'components/agent-templates/update-agent-template/UpdateAgentTemplateForm';
import { getAgentTemplate } from 'helpers/requests/agent-templates';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import Error404 from 'components/common/Error404';
import Loading from 'components/agent-templates/update-agent-template/Loading';

const UpdateAgentTemplate = () => {
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [agentTemplate, setAgentTemplate] = useState(null);
  const { id } = useParams();

  let handleGetAgentTemplate = async () => {
    const r = await getAgentTemplate(id);
    if (r.success) {
      setAgentTemplate(r.response.data);
      setLoading(false);
    } else {
      if (r.response.status === 404) {
        setNotFound(true);
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
  };

  useEffect(() => {
    handleGetAgentTemplate();
  }, []);

  if (notFound) {
    return <Error404 />;
  }
  if (loading) {
    return <Loading />;
  }
  if (!loading && !agentTemplate.owner) {
    return <Error404 />;
  }
  return <UpdateAgentTemplateForm agentTemplate={agentTemplate} />;
};

export default UpdateAgentTemplate;
