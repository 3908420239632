import { useAppContext } from 'contexts/AppContext';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor, rgbaColor } from 'helpers/utils';
import React, { forwardRef } from 'react';
import { fromISOInUTC } from 'helpers/time';

echarts.use([
  LineChart,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  CanvasRenderer
]);

const getOption = (data, datetimes, isDark) => ({
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'none'
    },
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    borderWidth: 1,
    transitionDuration: 0,
    textStyle: {
      fontWeight: 500,
      fontSize: 12,
      color: getColor('dark')
    },
    formatter: params => {
      const dt = fromISOInUTC(params[0].axisValue);
      const executionCount = params[0].value;
      const executionWord = executionCount === 1 ? 'execution' : 'executions';
      return `${dt.toFormat(
        'LLL dd, HH:mm'
      )} - ${executionCount} ${executionWord}`;
    }
  },
  xAxis: {
    type: 'category',
    data: datetimes,
    boundaryGap: false,
    splitLine: {
      show: true,
      lineStyle: {
        color: rgbaColor('#fff', 0.1)
      },
      interval: 0
    },
    axisLine: {
      lineStyle: {
        color: rgbaColor('#fff', 0.1)
      }
    },
    axisTick: {
      show: true,
      length: 10,
      lineStyle: {
        color: rgbaColor('#fff', 0.1)
      }
    },
    axisLabel: {
      color: getColor('gray-400'),
      fontWeight: 600,
      fontSize: 12,
      margin: 15,
      interval: window.innerWidth < 768 ? 'auto' : 0,
      formatter: value => fromISOInUTC(value).hour
    }
  },
  yAxis: {
    type: 'value',
    axisPointer: {
      show: false
    },
    splitLine: {
      show: false
    },
    axisLabel: {
      show: false
    },
    axisTick: { show: false },
    axisLine: { show: false }
  },
  series: [
    {
      type: 'line',
      smooth: true,
      data: data,
      symbol: 'emptyCircle',
      itemStyle: {
        color: isDark ? getColor('primary') : getColor('white')
      },
      lineStyle: {
        color: isDark
          ? rgbaColor(getColor('primary'), 0.8)
          : rgbaColor(getColor('white'), 0.8)
      },
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: isDark
                ? rgbaColor(getColor('primary'), 0.5)
                : rgbaColor('#fff', 0.5)
            },
            {
              offset: 1,
              color: isDark
                ? rgbaColor(getColor('primary'), 0)
                : rgbaColor('#fff', 0)
            }
          ]
        }
      },
      emphasis: {
        lineStyle: {
          width: 2
        }
      }
    }
  ],
  grid: { right: 15, left: 15, bottom: '15%', top: 0 }
});

const HourlyExecutionsChart = forwardRef(({ data, datetimes }, ref) => {
  const { isDark } = useAppContext();
  return (
    <ReactEChartsCore
      echarts={echarts}
      ref={ref}
      option={getOption(data, datetimes, isDark)}
    />
  );
});

export default HourlyExecutionsChart;
