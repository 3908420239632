import styled from '@emotion/styled';
import { getUseTemplateUrl } from 'components/agent-templates/helpers';
import { useAppContext } from 'contexts/AppContext';
import { useAuthContext } from 'contexts/AuthContext';
import React, { useEffect, useMemo, useState } from 'react';
import { Carousel, Col, Row } from 'react-bootstrap';
import LeftArrowBlack from '../../assets/img/icons/left-arrow-black.svg';
import LeftArrowWhite from '../../assets/img/icons/left-arrow-white.svg';
import RightArrowBlack from '../../assets/img/icons/right-arrow-black.svg';
import RightArrowWhite from '../../assets/img/icons/right-arrow-white.svg';
import Loading from './Loading';

const createCarouselWrapper = isDark =>
  styled.div({
    '& .next-prev-hide': {
      '& .carousel-control-prev, .carousel-control-next': {
        display: 'none'
      }
    },
    '& .carousel-control-prev': {
      right: 'calc(50% + 20px)',
      left: 'unset',
      top: 'unset',
      bottom: '-60px',
      height: 'fit-content',
      width: '20px',
      '& .carousel-control-prev-icon': {
        backgroundImage: `url(${isDark ? LeftArrowWhite : LeftArrowBlack})`
      }
    },
    '& .carousel-control-next': {
      left: 'calc(50% + 20px)',
      top: 'unset',
      bottom: '-60px',
      height: 'fit-content',
      width: '20px',
      '& .carousel-control-next-icon': {
        backgroundImage: `url(${isDark ? RightArrowWhite : RightArrowBlack})`
      }
    },
    '& .carousel-indicators': {
      display: 'none'
    },
    '@media screen and (min-width:992px) and (max-width: 1265px)': {
      '& .use-template-btn': {
        fontSize: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      '& .view-btn': {
        fontSize: '12px'
      },
      '& .description': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '3',
        WebkitBoxOrient: 'vertical'
      }
    }
  });

const CardWrapper = styled.div({
  '& .card-wrapper': {
    aspectRatio: '1'
  }
});

const CardSlider = ({ dataList, Card, block, loading }) => {
  const { isDark } = useAppContext();
  const { isAuthenticated } = useAuthContext();

  const CarouselWrapper = useMemo(
    () => createCarouselWrapper(isDark),
    [isDark]
  );

  const [size, setSize] = useState(
    window.innerWidth >= 768 && window.innerWidth <= 991 ? 2 : 4
  );

  const cardGroups = useMemo(
    () =>
      dataList.reduce(
        (acc, _, i) =>
          i % size === 0 ? [...acc, dataList.slice(i, i + size)] : acc,
        []
      ),
    [dataList, size]
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768 && window.innerWidth <= 991) {
        setSize(2);
      } else {
        setSize(4);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (loading) {
    return <Loading size={size} />;
  }

  return (
    <CarouselWrapper>
      <Carousel
        interval={null}
        controls={dataList.length > size ? true : false}
      >
        {cardGroups.map((group, index) => (
          <Carousel.Item key={index}>
            <Row>
              {group.map((cardData, index) => {
                const args =
                  block === 'bounty'
                    ? { bounty: cardData }
                    : {
                        agentTemplate: cardData,
                        useTemplateUrl: getUseTemplateUrl(
                          cardData.id,
                          isAuthenticated
                        )
                      };
                return (
                  <Col xs={12} md={6} lg={3} key={index}>
                    <CardWrapper>
                      <Card {...args} className="card-wrapper" />
                    </CardWrapper>
                  </Col>
                );
              })}
            </Row>
          </Carousel.Item>
        ))}
      </Carousel>
    </CarouselWrapper>
  );
};

export default CardSlider;
