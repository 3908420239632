import React from 'react';
import { Row, Col, Card, Placeholder } from 'react-bootstrap';

const PanelBody = ({ loading, bounty }) => {
  if (loading) {
    return (
      <>
        <hr className="my-0" />
        <Card.Body>
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={12} />
            <Placeholder xs={12} />
            <Placeholder xs={12} />
          </Placeholder>
        </Card.Body>
      </>
    );
  }

  if (!bounty.description) return null;

  return (
    <>
      <hr className="my-0" />
      <Card.Body>
        <Row className="d-flex justify-content-between">
          <Col xs={12}>
            <h5 className="mb-3 fs-0">{bounty.name}</h5>
          </Col>
          <Col xs={12}>
            <div
              dangerouslySetInnerHTML={{
                __html: bounty.description.replace(/\n/g, '<br>')
              }}
            />
          </Col>
        </Row>
      </Card.Body>
    </>
  );
};

export default PanelBody;
