import { ax } from 'helpers/requests/axios';
import { apiPaths } from 'config';

export async function createAgent(
  name,
  python_version,
  script,
  requirements,
  env_vars,
  store_id
) {
  const EXPECTED_STATUS_CODE = 201;

  try {
    const response = await ax.post(apiPaths.appAgents.agents.create, {
      name: name,
      python_version: python_version,
      script: script,
      ...(requirements !== undefined && { requirements: requirements }),
      ...(env_vars !== undefined && { env_vars: env_vars }),
      store_id: store_id
    });
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function updateAgent(id, payload) {
  const EXPECTED_STATUS_CODE = 204;
  const { name, python_version, script, requirements, env_vars, store_id } =
    payload;

  try {
    const response = await ax.patch(apiPaths.appAgents.agents.update(id), {
      ...(name !== undefined && { name: name }),
      ...(python_version !== undefined && { python_version: python_version }),
      ...(script !== undefined && { script: script }),
      ...(requirements !== undefined && { requirements: requirements }),
      ...(env_vars !== undefined && { env_vars: env_vars }),
      ...(store_id !== undefined && { store_id: store_id })
    });
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getAgent(id) {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appAgents.agents.detail(id));
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function deleteAgent(id) {
  const EXPECTED_STATUS_CODE = 204;
  try {
    const response = await ax.delete(apiPaths.appAgents.agents.delete(id));
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getAgents() {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appAgents.agents.list);
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getCronJobs(agentId) {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(
      apiPaths.appAgents.agentExecutorCronJobs.list,
      {
        params: { agent_id: agentId }
      }
    );
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function createAgentExecutorJob(agentId) {
  const EXPECTED_STATUS_CODE = 201;
  try {
    const response = await ax.post(
      apiPaths.appAgents.agentExecutorJobs.create,
      {
        agent_id: agentId
      }
    );
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function createAgentExecutorCronJob(name, expression, agentId) {
  const EXPECTED_STATUS_CODE = 201;
  try {
    const response = await ax.post(
      apiPaths.appAgents.agentExecutorCronJobs.create,
      {
        name: name,
        expression: expression,
        agent_id: agentId
      }
    );
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function deleteCronJob(id) {
  const EXPECTED_STATUS_CODE = 204;
  try {
    const response = await ax.delete(
      apiPaths.appAgents.agentExecutorCronJobs.delete(id)
    );
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}
export async function suspendCronJob(id) {
  const EXPECTED_STATUS_CODE = 204;
  try {
    const response = await ax.patch(
      apiPaths.appAgents.agentExecutorCronJobs.suspend(id)
    );
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function unsuspendCronJob(id) {
  const EXPECTED_STATUS_CODE = 204;
  try {
    const response = await ax.patch(
      apiPaths.appAgents.agentExecutorCronJobs.unsuspend(id)
    );
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function generateCronJobExpression(description) {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(
      apiPaths.appAgents.agentExecutorCronJobs.cronExpression,
      {
        params: { description: description }
      }
    );
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getExecutions(agentId) {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appAgents.executions.list, {
      params: { agent_id: agentId }
    });
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getBuild(id) {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appAgents.builds.detail(id));
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getLatestAgentBuilderJob(agentId) {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appAgents.agentBuilderJobs.latest, {
      params: { agent_id: agentId }
    });
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}
