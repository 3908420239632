import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Error404 from 'components/common/Error404';
import Executions from 'components/agents/agent-details/executions/Executions';
import CronJobs from 'components/agents/agent-details/cron-jobs/CronJobs';
import Panel from 'components/agents/agent-details/panel/Panel';
import Script from 'components/agents/agent-details/Script';
import EnvVars from 'components/agents/agent-details/EnvVars';
import Requirements from 'components/agents/agent-details/Requirements';
import { getAgent, getCronJobs } from 'helpers/requests/agents';
import { handleUnexpectedStatusCode } from 'helpers/errors';

const Agent = () => {
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [agent, setAgent] = useState({});
  const [cronJobs, setCronJobs] = useState([]);
  const { id } = useParams();

  let handleGetAgent = async () => {
    const r = await getAgent(id);
    if (r.success) {
      setAgent(r.response.data);
      setLoading(false);
    } else {
      if (r.response.status === 404) {
        setNotFound(true);
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
  };

  let handleGetCronJobs = async () => {
    const r = await getCronJobs(id);
    if (r.success) {
      setCronJobs(r.response.data);
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
  };
  const setNewCronJob = cronJob => {
    setCronJobs([...cronJobs, cronJob]);
  };

  useEffect(() => {
    handleGetAgent();
    handleGetCronJobs();
  }, []);

  if (notFound) {
    return <Error404 />;
  }

  return (
    <>
      <Panel
        loading={loading}
        agent={agent}
        agentId={id}
        setNewCronJob={setNewCronJob}
      />
      <CronJobs cronJobs={cronJobs} setCronJobs={setCronJobs} />
      {/* Rendering Executions only after we know for sure that the agent exists.
      Otherwise, 'Agent has no executions yet' message may flicker before Error404
      is shown. */}
      {!loading && (
        <>
          <Executions agentId={id} />
          <Script value={agent.script} isCollapsed={true} />
          <Requirements value={agent.requirements} isCollapsed={true} />
          <EnvVars value={agent.env_vars} isCollapsed={true} />
        </>
      )}
    </>
  );
};

export default Agent;
