import React from 'react';
import { Card, Placeholder, Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { appPaths } from 'config';

const PanelHeaderUnauthenticated = ({ loading, bounty }) => {
  if (loading) {
    return (
      <Card.Header>
        <Placeholder as={Card.Text} animation="glow">
          <Placeholder xs={1} />
        </Placeholder>
      </Card.Header>
    );
  }

  const applyToBountyUrl = `${
    appPaths.auth.register
  }?next=${appPaths.bounties.detail.replace(':id', bounty.id)}`;

  return (
    <Card.Header>
      <Row className="d-flex">
        <Col xs={6} className="d-flex align-items-center">
          <div className="d-flex align-items-center">
            <h5 className="fs-0 mb-0 d-flex align-items-center text-success">
              {bounty.amount_in_tokens} SNAI
            </h5>
          </div>
        </Col>
        <Col xs={6} className="d-flex justify-content-end align-items-center">
          <Button variant="primary" size="sm" as={Link} to={applyToBountyUrl}>
            Apply
          </Button>
        </Col>
      </Row>
    </Card.Header>
  );
};

export default PanelHeaderUnauthenticated;
