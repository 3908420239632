import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import UpdateBountyForm from 'components/bounties/update-bounty/UpdateBountyForm';
import { getBounty } from 'helpers/requests/bounties';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import Error404 from 'components/common/Error404';
import Loading from 'components/bounties/update-bounty/Loading';

const UpdateBounty = () => {
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [bounty, setBounty] = useState(null);
  const { id } = useParams();

  let handleGetBounty = async () => {
    const r = await getBounty(id);
    if (r.success) {
      setBounty(r.response.data);
      setLoading(false);
    } else {
      if (r.response.status === 404) {
        setNotFound(true);
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
  };

  useEffect(() => {
    handleGetBounty();
  }, []);

  if (notFound) {
    return <Error404 />;
  }
  if (loading) {
    return <Loading />;
  }
  if (!loading && !bounty.owner) {
    return <Error404 />;
  }
  return <UpdateBountyForm bounty={bounty} />;
};

export default UpdateBounty;
